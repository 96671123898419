import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Thoughts are rapidly tested. Project that concentrates on achieving user expectations and business goals.`}</p>
    </PageDescription>
    <h2>{`Software prototyping`}</h2>
    <Row className="post-video-row" mdxType="Row">
      <Column colMd={5} colLg={12} className="bx--type-expressive-heading-03" mdxType="Column">
        <div style={{
          backgroundColor: '#343444',
          padding: '2rem'
        }}>
          <img {...{
            "src": "/images/softpro.svg",
            "alt": "Software Prototyping"
          }}></img>
        </div>
      </Column>
      <Column colMd={5} colLg={12} className="bx--type-expressive-heading-03" mdxType="Column">
        <br />
Our first step is to design a prototype for your new software. This starts with our visit by our technology architects and meets detailed requirements for which we are responsible: 
        <ul>
          <li parentName="ul">{`extract all the information we need from you in order to avoid functional gaps `}</li>
          <li parentName="ul">{`add our own ideas and ideas based on experience in order to maximize your return on investments;`}</li>
        </ul>
        <p>{`Then we build your new system working (prototype) by using state-of-the-art visual software to see it work. This includes workflow diagrams that accurately map the user route, background processes and interactive user interfaces for showing the look and functionality of each screen.`}</p>
        <p>{`The prototype prevents assumption due to its visual nature and guarantees that everybody agrees on what is developed.`}</p>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      